<template>
    <GigPageWrapper
		:gig-id="String($attrs.id)" 
		current-route="gig-template"
		title="Gig template"
        @loaded="onCalendarWrapperLoaded"
	>
        <InlineNotification Heading="Create a gig template" class="mt-4" DismissId="GigTemplateNew">
            <template slot="body">
                <p>
                    Save time when creating new gigs by saving the current gig details as a gig template. 
                    You can edit this at anytime in the gig templates section of the app.
                </p>
            </template> 
        </InlineNotification>

        <div v-show="isLoaded" class="gigtemplate"> 
            <div class="details-view mb-72">
                <form class="form" ref="form" novalidate>

                    <div class="sm:col-span-3 grid grid-flow-col ">
                        <input-text  required id="name" ref="name" v-on:input="form.name = $event;" v-bind:value="form.name" label="Gig template name" hint="The template name must be populated e.g. Thursdays at Corkies"
                        error="The template name must be completed" placeholder="e.g. Thursdays at Corkies" name="name"/>
                    </div>

                    <div class="sm:col-span-6 grid grid-flow-col mt-4">
                        <input-text-area :rows="rowHeight" type="textarea"  required id="internal_desc" v-on:input="form.internal_desc = $event" v-bind:value="form.internal_desc" label="Internal description (not visible to artists)" hint="The internal description must be populated e.g. Use this for gigs involving the lightning seeds.  This field will only be shown to your team."
                        error="The internal description must be completed" placeholder="e.g. Use this template for any gig taking place at Thursday's with Joan" name="internal_desc"/>
                    </div> 

                    <Accordian heading="Artist Instructions" :toggle="accordians.open_artist_instructions">
                        <template #contents>
                            <div class="sm:col-span-6 grid grid-flow-col ">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="load_in_time" label="Load in times" name="load_in_time" v-on:input="form.load_in_time = $event" v-bind:value="form.load_in_time" placeholder="e.g. You can gain access to the room any time during the day. If you are planning on setting up earlier then please check with the venue first" />
                            </div>
                            <div class="sm:col-span-6 grid grid-flow-col ">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="sound_check_time" label="Sound check times" name="sound_check_time" v-on:input="form.sound_check_time = $event" v-bind:value="form.sound_check_time" placeholder="e.g. Must be checked by 7pm"  />
                            </div>
                            <div class="sm:col-span-6 grid grid-flow-col ">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="performance_times" label="Performance times" name="performance_times" 
                                placeholder="e.g. 7:30pm – 8:30pm, 9pm - 10pm "  v-on:input="form.performance_times = $event" v-bind:value="form.performance_times"  />
                            </div>
                            <h2 class="col-span-full pt-4">Equipment Details</h2>
                            <div class="sm:col-span-6 grid grid-flow-col  ">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="equipment_venue"  v-on:input="form.equipment_venue = $event" v-bind:value="form.equipment_venue" placeholder="e.g. Venue will only supply sound system" label="Equipment provided by venue" name="equipment_venue"/>
                            </div>
                            <div class="sm:col-span-6 grid grid-flow-col ">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="equipment_artist" v-on:input="form.equipment_artist = $event" v-bind:value="form.equipment_artist" placeholder="e.g. Must provide own stereo jack cables and mic cables." label="Equipment provided by artist" name="equipment_artist"/>
                            </div>
                            <div class="sm:col-span-6 grid grid-flow-col ">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="repertoire" label="Repertoire" name="repertoire"  v-on:input="form.repertoire = $event" v-bind:value="form.repertoire" placeholder="e.g. A good mix of old classics and current tunes is essential. Keep your sets as upbeat as possible, even if the venue doesn’t seem that busy."/>
                            </div>
                            <div class="sm:col-span-6 grid grid-flow-col ">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="dress_code" label="Dress code" name="dress_code" placeholder="e.g. Smart Casual" v-on:input="form.dress_code = $event" v-bind:value="form.dress_code" />
                            </div>
                            <div class="sm:col-span-6 grid grid-flow-col ">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="parking_details" label="Parking details" name="parking_details"  v-on:input="form.parking_details = $event" v-bind:value="form.parking_details" placeholder="e.g. Loading available outside of venue. Free public parking close to venue" />
                            </div>
                            <div class="sm:col-span-6 grid grid-flow-col ">
                                <input-text-area  :rows="rowHeight"  type="textarea"  id="additional_notes" v-on:input="form.additional_notes = $event" v-bind:value="form.additional_notes" label="Additional notes" name="additional_notes"  placeholder="e.g. Musician to ask for the manager on duty upon arrival"/>
                            </div>
                            <div class="sm:col-span-6  grid-flow-col">
                                <div class="mt-1 input-group">
                                    <label for="briefs" class="block text-sm font-medium text-gray-700"> Briefs </label>
                                    <div class="input-content min-h-[138px] tiptap-content">
                                        <tiptap @input="setBriefs" :id="'briefs'" :value="form.briefs" tabindex="0" class="tiptap-container"/>
                                        <div class="input-error"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="sm:col-span-6 grid-flow-col hidden">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="invoicing" v-on:input="form.invoicing = $event" v-bind:value="form.invoicing" label="Invoicing" name="invoicing" placeholder="e.g. If you are completing work on a regular weekly basis we ask that you submit your weekly invoice as promptly as possible, (ideally on a Sunday)." />
                            </div>
                            <div class="sm:col-span-6 grid-flow-col hidden">
                                <input-text-area :rows="rowHeight"  type="textarea"  id="important" v-on:input="form.important = $event" v-bind:value="form.important"  label="IMPORTANT INFORMATION" name="important" placeholder="e.g. Your Invoice must be made out to: ABC Music Ltd, 10 Samson Close" />
                            </div>
                            <div class="sm:col-span-6 grid grid-flow-col ">
                                <input-text-area :rows="rowHeight" required error="The payment terms must be completed" type="textarea"  id="payment_terms" v-on:input="form.payment_terms = $event" v-bind:value="form.payment_terms"  label="Payment terms" name="payment_terms" placeholder="e.g. We settle our invoices on the first Monday that falls at least 21 days from the receipt of your invoice." />
                            </div>
                        </template>
                    </Accordian> 

                    <Accordian heading="Fees" :toggle="accordians.open_fees">
                        <template #contents>
                            <Fees 
                                ref="fees" 
                                :gig-tiers="gigTiers"
                                :is-template="true"
                                :prefix="prefix"
                            />
                        </template>
                    </Accordian> 

                    <Accordian heading="Settings"  :toggle="accordians.open_settings">
                        <template #contents>
                            <div class="col-span-full py-4">
                                <h2>How do you want to book artists - use artists you already have as favourites, or let GigPig find them for you?</h2>
                            </div>

                            <div class="sm:col-span-1 grid grid-flow-col items-center">
                                <label for="enabled">
                                    Use my favourites lists
                                </label>
                                <t-toggle name="usefaves" id="usefaves"  ref="usefaves" v-model="use_faves" @click="switch_filter()" class="justify-self-end" />
                            </div>

                            <div class="sm:col-span-1 grid grid-flow-col items-center">
                                <label for="enabled">
                                    Let GigPig find artists for me
                                </label>
                                <t-toggle name="usefaves" id="usefaves"  ref="usesearch" v-model="use_search" @click="switch_filter()" class="justify-self-end" />
                            </div>                        
                        </template> 
                    </Accordian>

                    <Accordian heading="Configure Favourites" v-show="use_faves" :toggle="accordians.open_faves">
                        <template #contents>
                            <SearchByFave   ref="SearchByFave" 
                                :genres="genres"
                                :defaultFees="gigTiers" 
                                :passed_tiers="tiers"  
                                :passed_artistdirectory="passed_artistdirectory"
                                :passed_faveslists="passed_faveslists" 
                                :is_fetching_faves="loading_faves"
                                :is_fetching_directory="loading_directory"
                            />
                        </template>
                    </Accordian>

                    <Accordian heading="Configure Search" v-show="use_search" :toggle="true">
                        <template #contents>
                                <SearchByTag ref="SearchByTag"
                                    :search_tiers="form.search_tiers"
                                    :search_genres="form.search_genres"
                                    :single_tier="true"
                                    :tiers="tiers"
                                    :genres="genres"
                                />
                        </template>
                    </Accordian>
                </form>
            </div>
        </div>

        <div class="action-bar">   
			<GPButton 
				@click.native="save()" 
				:disabled="isLoading" 
				size="lg"
			><SaveIcon class="mr-2"/>Save template</GPButton> 
		</div>

        <NotificationSmall ref="notification" />

    </GigPageWrapper>
</template>

<script>
import { apiMethods } from '@/state/helpers'; 
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue'; 
import InlineNotification from '../../components/notifications/inline.vue';
import InputText from '../../components/form/InputText.vue';
import InputTextArea from '../../components/form/InputTextArea.vue';
import Accordian from '../../components/accordian/Accordian.vue';
import Fees from '../../components/gigs/Fees.vue';
import Pristine from '../../utils/pristine.js'
import { TToggle } from 'vue-tailwind/dist/components'
import SearchByFave from '../../components/gigs/SearchByFave.vue';
import { TAG_TYPES } from '../../state/models/tagType.js';
import SearchByTag from '../../components/gigs/SearchByTag.vue'; 
import GPButton from '../../components/ui/buttons/Button.vue';
import { SaveIcon } from "@vue-hero-icons/outline";
import NotificationSmall from '../../components/notifications/small.vue';
import NProgress from 'nprogress';
import Tiptap from "@/components/form/Tiptap.vue";

export default
{
    name: "CreateGigTemplate",
    components: {
        Accordian,
        GigPageWrapper,
        InlineNotification,
        InputText,
        InputTextArea,
        Fees,
        TToggle,
        SearchByFave,
        SearchByTag,
        GPButton,
        SaveIcon,
        NotificationSmall,
        Tiptap
    },
  watch: {
    item() {
      this.prefix = this.item && this.item.currency ? this.item.currency.symbol : '£';
    }
  },
    data() {
        return {
            isLoaded: false,
            isLoading:false,
            loading_faves: false,
            loading_directory: false,
            is_enabled: true,
            is_default: false, 
            use_faves: true,
            use_search: false,
            rowHeight: 5,
            gigTiers:[],
            genres: [],
            item: [],
            fees: [], 
            directory: [],
            tiers: [],
            passed_faveslists: [],
            passed_artistdirectory: [],
            accordians: {
                open_settings: true,
                open_fees: true,
                open_search: true,
                open_faves: true,
                open_artist_instructions: false                
            },
            pristine: null,
            pristineConfig: {
                classTo: 'input-group',
                errorClass: 'has-error',
                successClass: 'has-success',
                errorTextParent: 'input-error',
                errorTextTag: 'span',
                errorTextClass: 'text-help'
            }, 
            form: {
                name: '',
                internal_desc: '',
                equipment_artist: '',
                equipment_venue: '',
                repertoire: '',
                dress_code: '',
                parking_details: '',
                load_in_time: '',
                sound_check_time: '',
                performance_times: '',
                additional_notes: '',
                invoicing: '',
                important: '',
                payment_terms: '',
                briefs: '',
                allow_brand_visibility: false
            },
          prefix: '£'
        }
    },
    mounted(){
        this.loadGig();
    },
    methods: {
        ...apiMethods,
        setBriefs(value) {
            this.form.briefs = value
        },
        onCalendarWrapperLoaded() {
            if(!this.pristine && this.$refs.form){
                this.pristine = new Pristine(this.$refs.form, this.pristineConfig);
            }
        },
        switch_filter(){
            this.use_faves = !this.use_faves;
            this.use_search = !this.use_search;
        },
        loadGig() {
            
            this.isLoaded = false;
            NProgress.start();

            this.loading_directory = true;
            this.loading_faves = true;


            this.getFavesLists()
                .then((resp) => {
                    this.passed_faveslists = resp;
                }).finally(() => {
                    this.loading_faves = false;
                });            

            this.tiers = this.$store.getters['api/tiers'];

            let tags = this.$store.getters['api/artisttags'];
            this.genres = tags.filter(tag => tag.tag_type == TAG_TYPES.GENRE);

            this.getArtistDirectorySimple().then((resp) => {
                this.directory = resp;
                this.passed_artistdirectory = resp; 
            }).finally(() => {
                this.loading_directory = false;
            });

            this.pristine = new Pristine(this.$refs.form, this.pristineConfig); 

            const queryParams = [
                'artistTags',
                'gigTiers',
                'gigSearchTiers',
                'searchTiers',
                'gigTiers',
                'stagedArtists.artist',
                'stagedArtists.artist.artistTags',
              ]

            Promise.all([
                this.getGigChosenArtists({id: this.$attrs.id, queryParams: queryParams}).then((resp) => {
                    this.item = resp;
                })
            ]) .finally(() => {

                if (this.item)
                {
                    this.form.briefs = this.item.briefs;
                    this.form.equipment_artist = this.item.equipment_artist;
                    this.form.equipment_venue = this.item.equipment_venue;
                    this.form.repertoire = this.item.repertoire;
                    this.form.dress_code = this.item.dress_code;
                    this.form.parking_details = this.item.parking_details;
                    this.form.load_in_time = this.item.load_in_time;
                    this.form.sound_check_time = this.item.sound_check_time;
                    this.form.performance_times = this.item.performance_times;
                    this.form.additional_notes = this.item.additional_notes;
                    this.form.invoicing = this.item.invoicing;
                    this.form.important = this.item.important;
                    this.form.payment_terms = this.item.payment_terms;
                    
                    this.gigTiers = this.item.gig_tiers.map(t => {
                        return {
                            tier: t.tier,
                            requested_fee: t.requested_fee,
                            tier_id: t.tier.id,
                            id: t.tier.id
                        }
                    });

                    this.fees = this.gigTiers;

                    if (this.item.search_type === 100) {
                        this.$refs.SearchByFave.addedArtists = this.item.staged_artists.map((m) => {
                            return {
                                artist: m.artist,
                                fee: m.supersede_fee,
                                id: m.artist.id
                            }
                        })
                    }

                    if (this.item.search_type === 200) {
                        this.switch_filter();
                        this.$refs.SearchByTag.search_genres = this.item.artist_tags.map((m) => { return m.id });
                        this.$refs.SearchByTag.search_tiers = this.item.search_tiers.map((m) => { return m.id });
                    }

                  this.$store.dispatch('gig/getGigSummary', this.item.id)
                }
            });

            NProgress.done();
            this.isLoaded = true;
    },
    parseEnabled(){
        return  this.is_enabled ? 1 : 0;
    },
    parseDefault(){
        return  this.is_default ? 1 : 0;
    },
    save() {
        this.isLoading = true;

        var valid = this.pristine.validate(); 

        if (valid) {

            let tags = [];

            if (this.$refs.SearchByTag.search_genres != undefined) {
                tags = this.$refs.SearchByTag.search_genres;
            } 

            let payload = this.form;

            payload.is_enabled = this.parseEnabled();
            payload.is_default = this.parseDefault();
            payload.search_type = this.use_faves ? 100 : 200;
            payload.add_artists = this.$refs.SearchByFave.addedArtists;
            payload.tags = tags; 
            payload.fees = this.fees; 
            payload.tiers = this.$refs.SearchByTag.search_tiers;
            

            this.createGigTemplate(payload)
                .then(() => {          
                    this.$refs.notification.createNotification("Gig Template created");
                    this.isLoading = false;
                }).catch((error) => {
                    alert(error);
                    this.isLoading = false;
                })

        } else {
            this.$refs.notification.createNotification("Error! Check the form for omissions", true);
            this.isLoading = false;
        }

    }

    }
}
</script>